<route>
{
  "meta": {
    "permission": [
      "expenses.view_category"
    ]
  }
}
</route>

<template>
  <div>
    <dialcategory v-model="dialog" :to-edit="toEdit" @done="reload = true" />
    <i-table
      :title="$tc('category', 2)"
      :headers="headers"
      app="expenses.category"
      api="expense.category"
      :reload.sync="reload"
      @click:create="open()"
      @click:edit="open($event)"
    >
    </i-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import dialcategory from '../../components/expenseCategory/modal.vue'
export default {
  components: {
    dialcategory
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'name'
        },
        {
          text:
            this.categoryBtn.delete || this.categoryBtn.edit
              ? this.$t('action')
              : '',
          value:
            this.categoryBtn.delete || this.categoryBtn.edit ? 'actions' : ''
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    categoryBtn() {
      return {
        delete:
          this.getPermissions([`expenses.delete_category`]) || this.isAdmin,
        edit: this.getPermissions([`expenses.change_category`]) || this.isAdmin
      }
    }
  },
  data() {
    return {
      reload: false,
      dialog: false,
      toEdit: null
    }
  },
  methods: {
    open(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    }
  }
}
</script>
